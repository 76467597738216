<template>
  <div>
    <b-sidebar
      id="sidebar-edit-contract"
      sidebar-class="sidebar-lg"
      :visible="changeConsultantRoleSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateChangeConsultantRoleSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4>Alterar nível do consultor</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <div class="mb-2">
            <h5>{{ changeConsultantRoleSidebar.name }}</h5>
            <h6>{{ changeConsultantRoleSidebar.role }}</h6>
          </div>

          <b-form-group label="Novo nível" label-for="consultant-new-role">
            <v-select
              id="consultant-new-role"
              v-model="newConsultantRole"
              required
              :reduce="(role) => role.id_consultant_role"
              :options="roles"
              label="name"
              :loading="loadingRoles"
              :class="getSelectErrorClass(v$.newConsultantRole.$error)"
              @input="getChangeIsValid"
            />
            <div class="invalid-feedback">
              <span v-if="v$.newConsultantRole.required.$invalid">
                Você deve informar um novo nível para o consultor
              </span>
            </div>
          </b-form-group>
          <b-form-group
            v-if="franchiseNameRequired"
            label="Nome da franquia"
            label-for="consultant-new-franchise"
          >
            <b-form-input
              id="consultant-new-franchise"
              type="text"
              v-model="newFranchiseName"
              :class="{
                'is-invalid': v$.newFranchiseName.$error,
              }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.newFranchiseName.required.$invalid">
                Você deve informar um nome para a franquia do consultor
              </span>
            </div>
          </b-form-group>
          <div class="mb-2" v-if="!roleChangeIsValid">
            <span class="text-danger">
              A transferência para o nível selecionado não está disponível
              enquanto houverem consultores ativos abaixo do consultor
              escolhido.
            </span>
          </div>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="saving || !roleChangeIsValid"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <span v-if="!promotionChecklistRequired">
                {{ saving ? "Salvando..." : "Alterar nível" }}
              </span>
              <span v-else>
                {{ saving ? "Solicitando..." : "Solicitar promoção" }}
              </span>
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  PNF_ROLE_ID,
  EXPANSAO_ROLE_ID,
  EXECUTIVE_ROLES,
} from "@/constants/consultants";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import { required, requiredIf } from "@vuelidate/validators";
import { getVueSelectErrorClass } from "@/helpers/validators";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      saving: false,
      loadingRoles: false,
      roleChangeIsValid: true,
      newConsultantRole: undefined,
      newFranchiseName: undefined,
      promotionChecklistRequired: false,
    };
  },
  computed: {
    ...mapGetters({
      changeConsultantRoleSidebar: types.CHANGE_CONSULTANT_ROLE_SIDEBAR,
      roles: sharedTypes.ROLES,
    }),
    franchiseNameRequired: function () {
      return (
        this.newConsultantRole &&
        (this.newConsultantRole == PNF_ROLE_ID ||
          this.newConsultantRole == EXPANSAO_ROLE_ID)
      );
    },
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      newConsultantRole: { required },
      newFranchiseName: {
        required: requiredIf((value) => {
          return this.franchiseNameRequired;
        }),
      },
    };
  },
  mounted() {
    this.loadingRoles = true;
    this.getRoles()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os níveis para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loadingRoles = false;
      });
  },
  methods: {
    ...mapMutations({
      mutateChangeConsultantRoleSidebar:
        types.MUTATE_CHANGE_CONSULTANT_ROLE_SIDEBAR,
    }),
    ...mapActions({
      changeConsultantRole: types.CHANGE_CONSULTANT_ROLE,
      getRoles: sharedTypes.GET_ROLES,
      getRoleChangeIsValid: types.GET_ROLE_CHANGE_IS_VALID,
      getHasPromotionChecklist: types.GET_HAS_PROMOTION_CHECKLIST,
      getHasPendingPromotion: types.GET_HAS_PENDING_PROMOTION,
    }),
    onShow() {
      // this.getHasPendingPromotion(this.changeConsultantRoleSidebar.id)
      //   .then((response) => {
      //     if (response.data) {
      //       this.toast({
      //         component: ToastificationContent,
      //         props: {
      //           title: "Oops!",
      //           text: "O consultor já possui uma solicitação de promoção pendente.",
      //           icon: "AlertTriangleIcon",
      //           variant: "danger",
      //         },
      //       });
      //       this.mutateChangeConsultantRoleSidebar({ visible: false });
      //     }
      //   })
      //   .catch((error) => {
      //     this.toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: "Oops!",
      //         text: "Ocorreu um erro ao consultar se o consultor possui uma solicitação de promoção pendente. Entre em contato com o setor de TI.",
      //         icon: "AlertTriangleIcon",
      //         variant: "danger",
      //       },
      //     });
      //   });
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect || !this.roleChangeIsValid) return;
      this.saving = true;
      this.changeConsultantRole({
        consultant_id: this.changeConsultantRoleSidebar.id,
        new_role: this.newConsultantRole,
        franchise_name: this.newFranchiseName,
      })
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "A mudança de nível do consultor ocorreu com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutateChangeConsultantRoleSidebar({ visible: false });
          this.changeConsultantRoleSidebar.saveAction();
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: error.response.data.error,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          } else {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao atualizar o nível do consultor. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    getChangeIsValid() {
      if (_.includes(EXECUTIVE_ROLES, this.newConsultantRole)) {
        this.getRoleChangeIsValid({
          consultant_id: this.changeConsultantRoleSidebar.id,
          new_role: this.newConsultantRole,
        })
          .then((response) => {
            this.roleChangeIsValid = response.data.valid;
          })
          .catch((error) => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao consultar se a mudança de nível é válida. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
      // this.getHasPromotionChecklist(this.newConsultantRole)
      //   .then((response) => {
      //     this.promotionChecklistRequired = response.data;
      //   })
      //   .catch((error) => {
      //     this.toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: "Oops!",
      //         text: "Ocorreu um erro ao consultar se a mudança de nível requer checklist de promoção. Entre em contato com o setor de TI.",
      //         icon: "AlertTriangleIcon",
      //         variant: "danger",
      //       },
      //     });
      //   });
    },
    clear() {
      this.newConsultantRole = undefined;
      this.newFranchiseName = undefined;
      this.roleChangeIsValid = true;
      this.v$.$reset();
    },
  },
};
</script>
